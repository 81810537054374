<template>
  <div :id="anchorName" class="article2-owl owl-has-nav owl--auto-width">
    <h3 v-if="name" class="dynamic-main-title tmw-40-32-15">{{ name }}</h3>
    <v-container class="inner-container">
      <Owl :options="computedOptions" :refreshKey="refreshKey">
        <template v-slot:items>
          <div v-for="item in items" :key="item.id">
            <ArticleItem :item="item" />
          </div>
        </template>
      </Owl>
      <component
        :is="bottomWaveComponent"
        class="bottom-wave"
        :class="bottomWaveClass"
      ></component>
    </v-container>
  </div>
</template>

<script>
import dynamicBlock from "@/components/dynamic/dynamicBlock.js";
import ArticleItem from "./item/articleItem2.vue";
import owl from "../owl.js";
export default {
  mixins: [owl, dynamicBlock],
  components: {
    ArticleItem,
  },
  data: () => ({
    defaultOptions: {
      rewind: false,
      responsive: {
        0: {
          items: 1,
          nav: false,
          dots: false,
          autoWidth: true,
          margin: 15,
        },
        576: {
          items: 2,
          nav: false,
          dots: false,
          autoWidth: true,
          margin: 15,
        },
        769: {
          items: 3,
          nav: true,
          dots: false,
          autoWidth: true,
          margin: 30,
        },
        992: {
          items: 4,
          nav: true,
          dots: false,
          autoWidth: true,
          margin: 30,
        },
      },
    },
    refreshKey: 0,
  }),
  computed: {
    computedOptions() {
      return {
        ...this.defaultOptions,
        ...this.options,
      };
    },
  },
  methods: {},
};
</script>